import React, { useEffect, useRef, useState } from "react";

//import videos from "./videos";
import VideoPlayer from "./VideoPlayer";
import {
  getVideosByCourseId,
  getUser,
  getUserVideosCompleted,
  addUserVideoCompleted,
} from "services/kmbizdev.service";

const VideoNavigator = ({ courseRouteId }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [shouldAutoPlay, setShouldAutoPlay] = useState(false);
  const [completedVideos, setCompletedVideos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [userEmail, setUserEmail] = useState("");
  const videoRef = useRef(null);
  const [completedTriggered, setCompletedTriggered] = useState(false);

  const navigateToVideo = (index) => {
    const videoCount = videos.length;
    const newIndex = (index + videoCount) % videoCount; // Ensure index wraps around
    setCurrentIndex(newIndex);
    setShouldAutoPlay(true);
  };

  useEffect(() => {
    // Update current video when currentIndex changes
    setCurrentVideo(videos[currentIndex]);
    if (videoRef.current) {
      const offset = 0; // Adjust this value to set how far above the video player you want to scroll
      const videoPosition =
        videoRef.current.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top: videoPosition, behavior: "smooth" });
    }
  }, [currentIndex, videos]);

  useEffect(() => {
    // Reset completion trigger on video change
    setCompletedTriggered(false);
  }, [currentIndex]); // Triggered when currentIndex changes

  useEffect(() => {
    async function fetchInitialData() {
      const currentUser = await getUser();
      //console.log(" currentUser", currentUser);
      setUserEmail(currentUser.email);
      const videosResponse = await getVideosByCourseId(courseRouteId);
      const completedVideosResponse = await getUserVideosCompleted(
        currentUser.email
      );

      if (videosResponse.data) {
        setVideos(videosResponse.data);
        setCurrentVideo(videosResponse.data[0]);
      }
      if (completedVideosResponse.successful) {
        setCompletedVideos(completedVideosResponse.data);
      }
      setIsLoading(false);
    }

    if (courseRouteId) {
      try {
        fetchInitialData();
      } catch (error) {
        console.error("Error fetching initial data", error);
      }
    }
  }, [courseRouteId]);

  // const handleVideoCompletion = async (videoId) => {
  //   if (!completedVideos.includes((x) => x.videoId === videoId)) {
  //     const response = await addUserVideoCompleted(userEmail, videoId);

  //     if (response && response.successful) {
  //       setCompletedVideos(response.data);
  //     }
  //   }
  // };

  const handleVideoCompletion = async (videoId) => {
    if (
      !completedTriggered &&
      !completedVideos.find((x) => x.videoId === videoId)
    ) {
      setCompletedTriggered(true);
      const response = await addUserVideoCompleted(userEmail, videoId);
      if (response && response.successful) {
        setCompletedVideos(response.data);
      }
    }
  };

  if (loading)
    return (
      <div className="container mx-2 my-2 flex justify-center text-xl">
        <h1>Loading Course Contents...</h1>
      </div>
    );

  return (
    <>
      <div className="container mx-auto flex pb-4">
        <div
          className=" flex flex-col border-black border-2 shadow-lg"
          ref={videoRef}
        >
          <VideoPlayer
            key={currentIndex}
            shouldAutoPlay={shouldAutoPlay}
            currentVideo={currentVideo}
            onTimeUpdate={(percentage) => {
              if (
                percentage >= 85 &&
                !completedVideos.find((x) => x.videoId === currentVideo.videoId)
              ) {
                handleVideoCompletion(currentVideo.videoId);
              }
            }}
            foundInCompletedVideos={completedVideos.find(
              (x) => x.videoId === currentVideo.videoId
            )}
          />
          <div className="flex justify-center my-4 mx-4 px-4">
            <div className="mx-4">
              <button
                className="bg-lightBlue-500 text-white font-bold uppercase text-base p-2 rounded shadow-md hover:shadow-lg  outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button"
                onClick={() => navigateToVideo(currentIndex - 1)}
              >
                Previous
              </button>
            </div>
            <div className="mx-4">
              <button
                className="bg-lightBlue-500 text-white font-bold uppercase text-base p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                onClick={() => navigateToVideo(currentIndex + 1)}
              >
                Next
              </button>
            </div>
          </div>
          <div className="container pb-4">
            <ul className="flex flex-wrap justify-center text-md">
              {videos.map((video, index) => {
                let found = completedVideos.find(
                  (x) => x.videoId === video.videoId
                );
                return (
                  <li
                    key={index}
                    className={`p-2 m-2 text-white rounded ${
                      found
                        ? "bg-emerald-400  cursor-pointer shadow-lg "
                        : "bg-lightBlue-400 cursor-pointer shadow-lg"
                    } ${
                      currentIndex === index && !found && "bg-lightBlue-600"
                    } ${currentIndex === index && found && "bg-emerald-500"}`}
                    onClick={() => navigateToVideo(index)}
                  >
                    <i
                      className={`px-2 ${
                        found ? "fas fa-check" : "far fa-circle"
                      }`}
                    ></i>
                    {video.videoTitle}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoNavigator;
