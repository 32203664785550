import React, { useEffect, useState } from "react";

// components

import CardSettings from "components/Cards/CardSettings.js";
import CardProfile from "components/Cards/CardProfile.js";
import { getUserInfo } from "services/kmbizdev.service";
import { getUser } from "services/kmbizdev.service";

export default function Settings() {
  let [currentUser, setCurrentUser] = useState("");

  useEffect(() => {
    setCurrentUser(getUserInfo());

    let user = JSON.parse(localStorage.getItem("currentUser"));
    console.log("user", user);

    if (user && user.token) {
      if (new Date(user.expiration).valueOf() < Date.now()) {
        window.location = "/auth/login";
      }
    }
  }, []);

  useEffect(() => {
    console.log(currentUser);
  }, [currentUser]);
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardSettings user={currentUser} />
        </div>
      </div>
    </>
  );
}
