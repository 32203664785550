import axios from "axios";
import { jwtDecode } from "jwt-decode";

//const api_url = appointmentsApiUrl()
var api_url = "http://localhost:5000";

const api = axios.create({
  baseURL: "https://api.kmbizdev.com/Api",
});

api.interceptors.request.use(
  (config) => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      config.headers.Authorization = `Bearer ${currentUser.token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

async function AxiosPost(endpoint, data, jwt) {
  let x = await api.post(`${endpoint}`, data).then((responseBody) => {
    return responseBody.data;
  });

  return x;
}

async function AxiosDelete(endpoint, data, jwt) {
  let x = await api.delete(`${endpoint}`).then((responseBody) => {
    return responseBody.data;
  });

  return x;
}

async function AxiosGet(endpoint, params, jwt) {
  let x = await api.get(`${endpoint}`).then((responseBody) => {
    return responseBody.data;
  });

  return x;
}

export async function submitLeadInfo(payload) {
  return await AxiosPost("/Home/Submit", payload);
}

export async function login(payload) {
  let user = await AxiosPost("/Authentication/Login", payload);

  if (user.successful && user.data.token) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    user.data.twoFactorPass = true;
    localStorage.setItem("currentUser", JSON.stringify(user.data));
  }
  return user;
}

export async function getUser() {
  let user = await AxiosGet("/Users/Profile");

  if (user.successful) return user.data;
  else return user;
}

export function getUserInfo() {
  var jwt = localStorage.getItem("currentUser");
  return jwtDecode(jwt);
}

export function getUserRoles() {
  var jwt = localStorage.getItem("currentUser");
  var jwtDecoded = jwtDecode(jwt);
  return jwtDecoded.roles;
}

export async function register(payload) {
  return await AxiosPost("/Authentication/AddUser", payload);
}

export async function getVideosByCourseId(courseId) {
  return await AxiosGet(`/Courses/GetVideosForCourse?courseId=${courseId}`);
}

export async function getCourseTitle(courseId) {
  return await AxiosGet(`/Courses/GetCourseTitle?courseId=${courseId}`);
}

export async function addUserVideoCompleted(email, videoId) {
  return await AxiosPost("/Courses/VideoCompleted", {
    UserEmail: email,
    VideoId: videoId,
  });
}

export async function getUserVideosCompleted(email) {
  return await AxiosGet(`/Courses/GetCompletedVideos?userEmail=${email}`);
}

/*export async function getAllAppointments(jwt, peerId, start, end, orgId) {
  // console.log(peerId)
  return await AxiosGet(jwt, "/OnSched/All", {
    start: start,
    end: end,
    peerId: peerId,
    orgId: orgId,
  });
}
*/
