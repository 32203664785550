import React from "react";
import { Link } from "react-router-dom";

const CourseLink = ({ sourceImage, spanText, paragraphText, routeID }) => {
  return (
    <div className="flex flex-col lg:flex-row justify-start px-4 mx-4 items-center my-6 shadow-xl rounded-lg bg-lightBlue-600">
      <div className="flex flex-wrap justify-center w-1/2">
        <div className="w-12/12 lg:w-6/12 h-6/12 sm:w-4/12 px-4 py-4 mr-4">
          <img
            src={sourceImage}
            alt="..."
            className="shadow-lg rounded-full max-w-full h-auto min-w-48 align-middle border-2 "
          />
        </div>
      </div>
      <span className="text-lg font-semibold inline-block py-1 px-4 text-center rounded-full text-white bg-lightBlue-400 uppercase last:mr-0 mr-1">
        {spanText}
      </span>
      <p className="w-12/12 lg:w-6/12 bg-white p-4 mr-4 m-4 text-lg text-center rounded shadow-lg">
        <b>{paragraphText}</b>
      </p>
      <Link
        to={`/admin/courses/${routeID}`}
        className="m-4 ml-3 hover:scale-150"
      >
        <button className="bg-lightBlue-400 text-white active:bg-lightBlue-600 font-bold uppercase text-base p-2 rounded shadow-md hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150">
          View Course
        </button>
      </Link>
    </div>
  );
};

export default CourseLink;
