import React from "react";

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Packages() {
  return (
    <>
      <Navbar transparent />
      <main className="packages-page">
        <section className="relative block" style={{ height: "120px" }}>
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
        </section>
        <section className="relative bg-blueGray-200">
          <div className="flex flex-wrap justify-center text-center mb-12 mt-12">
            <div className="w-full lg:w-6/12 px-4">
              <h2 className="text-4xl font-semibold">Full Service Packages</h2>
            </div>
          </div>
          <div
            className="flex flex-wrap"
            style={{ justifyContent: "space-around" }}
          >
            <div className="flex flex-col min-w-0 break-words bg-white mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    Community All Access
                  </h3>

                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $99/month
                  </div>
                  <div className="border-t"></div>
                </div>
                <div className="text-left mt-5 max-w-450-px">
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">1.</b>
                    Monthly KM community zoom call covering sales, systems,
                    guest speakers and more
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">2.</b>
                    Access to private KM members chat where CEO Cody Kerns
                    shares useful tips
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">3.</b>1
                    on 1 coaching call with VP Justin Freishtat who has
                    generated 8 figures in sales revenue.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">4.</b>
                    Direct access to KM Execs for questions, consulting &
                    support.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">5.</b>
                    Discounts on all KM offerings and ability to earn
                    commissions
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">6.</b>
                    Access to 40+ page Blueprint to Millions
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">7.</b>
                    Access to 20+ video Millions to Social course presented by
                    Cody Kerns
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">8.</b>
                    Access to private KM engagement chat to increase comments,
                    likes and views with other KM members.
                  </div>
                  <a href="https://km.kmbizdev.com/join-the-km-community">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Now{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col min-w-0 break-words bg-white mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    The Boost Pack
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $995/month
                  </div>
                  <div className="border-t"></div>
                </div>
                <div className="text-left mt-5 max-w-450-px">
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">1.</b>
                    5,000-10,000+ followers monthly
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">2.</b>
                    500-1K+ likes per post
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">3.</b>
                    5,000-10,000 views per video
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">4.</b>
                    20-50 comments per post
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">5.</b>
                    20-50 shares per post
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">6.</b>+
                    All KM Community Perks
                  </div>
                  <a href="https://app.hubspot.com/payments/g9DdYCFv9Wmnv2pG?referrer=PAYMENT_LINK">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Now{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col min-w-0 break-words bg-white mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    Fractional COO Package
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $2,995/Quarter or $1,295/month
                  </div>
                  <div className="border-t"></div>
                </div>
                <div className="text-left mt-5 max-w-450-px">
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">1.</b>
                    You will be assigned a personal social media manager that
                    will assist in brand development, growth and reach.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">2.</b>
                    We will teach YOU how to collaborate, prospect, increase
                    connections and lead flow via Instagram DM’s. Whether your
                    ideal audience is worldwide, nationwide or local, we will
                    give you the formula to dramatically increase lead flow!
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">3.</b>
                    Publication & Press releases. You will receive a total of 1
                    publication per quarter based on the questionnaire you
                    complete. These publications will rank on Google, help with
                    SEO and highlight any area of your business and success
                    you’d like to share.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">4.</b>
                    One on one monthly consultation phone or video call with the
                    $100M management team to go over performance, targets, goals
                    set & more.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">5.</b>
                    Business Analysis: We'll pinpoint and implement the exact
                    automations, sequences, and systems you need to align with
                    your goals and DRIVE revenues to your business.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">6.</b>
                    Event Access. You will receive 2 entry tickets to annual
                    events hosted by Kerns Marketing. These events can take
                    place all over the USA.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">7.</b>
                    KM Private chat community access to further network,
                    collaborate and connect with likeminded people. 8. Email
                    Group Chain And Customer Support Monday through Friday (9am
                    to 6pm EST)
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">8.</b>
                    Access to the Blueprint to Millions on social media. This
                    ebook has the secrets of collaboration, increasing your
                    online revenues and much more.
                  </div>
                  <a href="https://app.hubspot.com/payments/4rG6gVgKrtQ6h?referrer=PAYMENT_LINK">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Monthly{" "}
                    </button>
                  </a>
                  <a href="https://app.hubspot.com/payments/9bC7T9W4?referrer=PAYMENT_LINK">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Quarterly{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col min-w-0 break-words bg-white mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    Silver Plan
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $6,995/Quarter or $2,495/month
                  </div>
                  <div className="border-t"></div>
                </div>
                <div className="text-left mt-5 max-w-450-px">
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">1.</b>
                    You will be assigned a personal social media manager that
                    will assist in brand development, growth and reach.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">2.</b>
                    Personal USA based social media manager assigned to your
                    account will send outbound messages to increase lead flow,
                    engagement, conversations, connections and more. These
                    outbound messages can increase your revenue by multiples.
                    All leads will be tracked via Google sheets with the account
                    owner.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">3.</b>
                    Content creation including 10 edits per month. These edits
                    can also be used for any ads you are running or plan to run.
                    The edits can be posted across all social channels. Footage
                    must be provided.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">4.</b>
                    Publication & Press releases. You will receive a total of 1
                    publication per quarter based on the questionnaire you
                    complete. These publications will rank on Google, help with
                    SEO and highlight any area of your business and success
                    you’d like to share.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">5.</b>
                    One on one monthly consultation phone or video call with the
                    $100M management team to go over performance, targets, goals
                    set & more.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">6.</b>
                    Business Analysis: We'll pinpoint and implement the exact
                    automations, sequences, and systems you need to align with
                    your goals and DRIVE revenues to your business.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">7.</b>
                    Event Access. You will receive 2 entry tickets to annual
                    events hosted by Kerns Marketing. These events can take
                    place all over the USA.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">8.</b>
                    Email, Call and Text Customer Support Monday through Friday.
                    9am to 6pm
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">9.</b>
                    KM Private chat community access to further network,
                    collaborate and connect with likeminded people. 8. Email
                    Group Chain And Customer Support Monday through Friday (9am
                    to 6pm EST)
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">10.</b>
                    Access to the Blueprint to Millions on social media. This
                    ebook has the secrets of collaboration, increasing your
                    online revenues and much more.
                  </div>
                  <a href="https://app.hubspot.com/payments/TYyyxfh6f?referrer=PAYMENT_LINK">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Monthly{" "}
                    </button>
                  </a>
                  <a href="https://app.hubspot.com/payments/P6kf4TWWkSvMZFb?referrer=PAYMENT_LINK">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Quarterly{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col min-w-0 break-words bg-white mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    The COO Package
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $9,995/Quarter or $3,495/month
                  </div>
                  <div className="border-t"></div>
                </div>
                <div className="text-left mt-5 max-w-450-px">
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">1.</b>
                    You will be assigned a personal social media manager that
                    will assist in brand development, growth and reach.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">2.</b>
                    Personal USA based social media manager assigned to your
                    account will send outbound messages to increase lead flow,
                    engagement, conversations, connections and more. These
                    outbound messages can increase your revenue by multiples.
                    All leads will be tracked via Google sheets with the account
                    owner.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">3.</b>
                    Facebook/IG combo OR Google/YouTube combo advertisement
                    creation and management targeted towards your niche audience
                    resulting in increased exposure and lead flow. We will
                    create an ad spend budget with you, this ad spend is NOT
                    included in the package cost
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">4.</b>
                    On-site content creation once every 90 days, one of our
                    select filmers and or photographers will travel to your
                    location to create video/photo that can be used for all
                    social channels. This content can also be used for any ads
                    you are running or plan to. You will receive 15 edited
                    videos from this session.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">5.</b>
                    We will post on all account owner social media channels 3-7
                    times per week.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">6.</b>
                    We will brainstorm and execute on campaigns, promotions and
                    giveaways agreed on by the account owner to build a
                    community and dramatically increase engagement.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">7.</b>
                    Publication & Press releases. You will receive a total of 1
                    publication per quarter based on the questionnaire you
                    complete. These publications will rank on Google, help with
                    SEO and highlight any area of your business and success
                    you’d like to share.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">8.</b>
                    One on one monthly consultation phone or video call with the
                    $100M management team to go over performance, targets, goals
                    set & more.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">9.</b>
                    Business Analysis: We'll pinpoint and implement the exact
                    automations, sequences, and systems you need to align with
                    your goals and DRIVE revenues to your business.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">10.</b>
                    Event Access. You will receive 2 entry tickets to annual
                    events hosted by Kerns Marketing. These events can take
                    place all over the USA.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">11.</b>
                    Email, Call and Text Customer Support Monday through Friday.
                    9am to 6pm
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">12.</b>
                    KM Private chat community access to further network,
                    collaborate and connect with likeminded people. 8. Email
                    Group Chain And Customer Support Monday through Friday (9am
                    to 6pm EST)
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">13.</b>
                    Access to the Blueprint to Millions on social media. This
                    ebook has the secrets of collaboration, increasing your
                    online revenues and much more.
                  </div>
                  <a href="https://app.hubspot.com/payments/hxDhbtcTygVr9?referrer=PAYMENT_LINK">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Monthly{" "}
                    </button>
                  </a>
                  <a href="https://app.hubspot.com/payments/fSvRkJhfWYWMvs?referrer=PAYMENT_LINK">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Quarterly{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col min-w-0 break-words bg-white mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    The Platinum Package
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $14,995/Quarter or $5,995/month
                  </div>
                  <div className="border-t"></div>
                </div>
                <div className="text-left mt-5 max-w-450-px">
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">1.</b>
                    You will be assigned a personal social media manager that
                    will assist in brand development, growth and reach.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">2.</b>
                    Personal USA based social media manager assigned to your
                    account will send outbound messages to increase lead flow,
                    engagement, conversations, connections and more. These
                    outbound messages can increase your revenue by multiples.
                    All leads will be tracked via Google sheets with the account
                    owner.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">3.</b>
                    Facebook/IG/Google/YouTube combo advertisement creation and
                    management targeted towards your niche audience resulting in
                    increased exposure and lead flow. We will create an ad spend
                    budget with you, this ad spend is NOT included in the
                    package cost
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">4.</b>
                    On-site content creation once every 90 days, one of our
                    select filmers and or photographers will travel to your
                    location to create video/photo that can be used for all
                    social channels. This content can also be used for any ads
                    you are running or plan to. You will receive 15 edited
                    videos from this session.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">5.</b>
                    We will post on all account owner social media channels 3-7
                    times per week.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">6.</b>
                    We will brainstorm and execute on campaigns, promotions and
                    giveaways agreed on by the account owner to build a
                    community and dramatically increase engagement.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">7.</b>
                    Publication & Press releases. You will receive a total of 1
                    publication per quarter based on the questionnaire you
                    complete. These publications will rank on Google, help with
                    SEO and highlight any area of your business and success
                    you’d like to share.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">8.</b>
                    One on one bi-weekly consultation phone or video call with
                    the $100M management team to go over performance, targets,
                    goals set & more.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">9.</b>
                    Business Analysis: We'll pinpoint and implement the exact
                    automations, sequences, and systems you need to align with
                    your goals and DRIVE revenues to your business.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">10.</b>
                    Event Access. You will receive 2 entry tickets to annual
                    events hosted by Kerns Marketing. These events can take
                    place all over the USA.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">11.</b>
                    One page JotForm creation to capture any and all leads
                    generated by the ongoing advertising campaigns.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">12.</b>
                    KM Private chat community access to further network,
                    collaborate and connect with likeminded people. 8. Email
                    Group Chain And Customer Support Monday through Friday (9am
                    to 6pm EST)
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">13.</b>
                    Access to the Blueprint to Millions on social media. This
                    ebook has the secrets of collaboration, increasing your
                    online revenues and much more.
                  </div>
                  <a href="https://app.hubspot.com/payments/n7FDcb9JDYyy?referrer=PAYMENT_LINK">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Monthly{" "}
                    </button>
                  </a>
                  <a href="https://app.hubspot.com/payments/TkTKGWWtJ9sT?referrer=PAYMENT_LINK">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Quarterly{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col min-w-0 break-words bg-white mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    The Celebrity Package
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $125,000 Annually or $34,995.00/Quarter
                  </div>
                  <div className="border-t"></div>
                </div>
                <div className="text-left mt-5 max-w-450-px">
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">1.</b>
                    You will be assigned a personal social media manager that
                    will assist in brand development, growth and reach.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">2.</b>
                    Personal USA based social media manager assigned to your
                    account will send outbound messages to increase lead flow,
                    engagement, conversations, connections and more. These
                    outbound messages can increase your revenue by multiples.
                    All leads will be tracked via Google sheets with the account
                    owner.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">3.</b>
                    Facebook/IG/Google/YouTube combo advertisement creation and
                    management targeted towards your niche audience resulting in
                    increased exposure and lead flow. We will create an ad spend
                    budget with you, this ad spend is NOT included in the
                    package cost
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">4.</b>
                    On-site content creation once every 90 days, one of our
                    select filmers and or photographers will travel to your
                    location to create video/photo that can be used for all
                    social channels. This content can also be used for any ads
                    you are running or plan to. You will receive 15 edited
                    videos from this session.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">5.</b>
                    We will post on all account owner social media channels 3-7
                    times per week.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">6.</b>
                    We will brainstorm and execute on campaigns, promotions and
                    giveaways agreed on by the account owner to build a
                    community and dramatically increase engagement.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">7.</b>
                    Publication & Press releases. You will receive a total of 1
                    publication per quarter based on the questionnaire you
                    complete. These publications will rank on Google, help with
                    SEO and highlight any area of your business and success
                    you’d like to share.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">8.</b>
                    One on one bi-weekly consultation phone or video call with
                    the $100M management team to go over performance, targets,
                    goals set & more.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">9.</b>
                    Business Analysis: We'll pinpoint and implement the exact
                    automations, sequences, and systems you need to align with
                    your goals and DRIVE revenues to your business.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">10.</b>
                    Event Access. You will receive 2 entry tickets to annual
                    events hosted by Kerns Marketing. These events can take
                    place all over the USA.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">11.</b>
                    One page JotForm creation to capture any and all leads
                    generated by the ongoing advertising campaigns.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">12.</b>
                    KM Private chat community access to further network,
                    collaborate and connect with likeminded people. 8. Email
                    Group Chain And Customer Support Monday through Friday (9am
                    to 6pm EST)
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">13.</b>
                    Access to the Blueprint to Millions on social media. This
                    ebook has the secrets of collaboration, increasing your
                    online revenues and much more.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">14.</b>
                    Instagram wall post on @CodyKerns5 Instagram account
                    promoting you and your services. Publication & Press
                    releases. You will receive a total of 4 publications on
                    various PR websites per quarter based on the questionnaire
                    you complete. These publications will rank on Google, help
                    with SEO and highlight any area of your business and success
                    you’d like to share.
                  </div>
                  <a href="https://app.hubspot.com/payments/pKs4Pp969?referrer=PAYMENT_LINK">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Quarterly{" "}
                    </button>
                  </a>
                  <a href="https://app.hubspot.com/payments/XMGPj9m4YF?referrer=PAYMENT_LINK">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Anually{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative bg-blueGray-200">
          <div className="flex flex-wrap justify-center text-center mb-12 mt-12">
            <div className="w-full lg:w-6/12 px-4">
              <h2 className="text-4xl font-semibold">A La Carte Services</h2>
            </div>
          </div>
          <div
            className="flex flex-wrap"
            style={{ justifyContent: "space-around" }}
          >
            <div className="flex flex-col min-w-0 break-words bg-white mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    Follower Growth
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $995 One-time payment
                  </div>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $99/month thereafter
                  </div>
                  <div className="border-t"></div>
                </div>
                <div className="text-left mt-5 max-w-450-px">
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">1.</b>
                    10-15K Followers: $595.00
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">2.</b>
                    20-25K Followers: $995.00
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">3.</b>1
                    50-60K Followers: $1,995.00
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">4.</b>
                    100K Followers $2,995.00
                  </div>
                  {/*<a href="">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Now{" "}
                    </button>
                  </a>*/}
                </div>
              </div>
            </div>
            <div className="flex flex-col min-w-0 break-words bg-white mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    Coaching & Consulting
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $2,995/Quarter or $1,295/month
                  </div>
                  <div className="border-t"></div>
                </div>
                <div className="text-left mt-5 max-w-450-px">
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">1.</b>
                    Quarterly Consult. 3 monthly calls, total 60 Min One on One
                    With Cody $2,500.00
                  </div>
                  {/*<a href="">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Now{" "}
                    </button>
                  </a>*/}
                </div>
              </div>
            </div>
            <div className="flex flex-col min-w-0 break-words bg-white mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    SEO Publication / Press Release
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $6,995/Quarter or $2,495/month
                  </div>
                  <div className="border-t"></div>
                </div>
                <div className="text-left mt-5 max-w-450-px">
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">1.</b>
                    Bronze Tier 3 pack $995
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">2.</b>
                    Silver Tier 3 Pack $2995
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">3.</b>
                    Gold Tier 3 Pack $4995
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">4.</b>
                    Elite Tier ($10-50K+ Must Be Approved)
                  </div>
                  {/*<a href="">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Now{" "}
                    </button>
                  </a>*/}
                </div>
              </div>
            </div>
            <div className="flex flex-col min-w-0 break-words bg-white mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    CRM Infrastructure
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $9,995/Quarter or $3,495/month
                  </div>
                  <div className="border-t"></div>
                </div>
                <div className="text-left mt-5 max-w-450-px">
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">1.</b>
                    One Time Payment of $4,995 for CRM build out. Includes 90
                    days of support, CRM setup, 3 follow up sequences
                    integrated, email newsletter creation and data integration
                    from your site.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">2.</b>
                    Landing page and or JotForm lead capture to connect to CRM
                    and capture data, add on $995
                  </div>
                  {/*<a href="">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Now{" "}
                    </button>
                  </a>*/}
                </div>
              </div>
            </div>
            <div className="flex flex-col min-w-0 break-words bg-white mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    Account Management
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $14,995/Quarter or $5,995/month
                  </div>
                  <div className="border-t"></div>
                </div>
                <div className="text-left mt-5 max-w-450-px">
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">1.</b>
                    Instagram outbound prospecting 150-200 targeted messages
                    sent per month to generate leads. $995 per month
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">2.</b>
                    Social media posting to all channels (IG/YT/FB & Goog) $995
                    per month
                  </div>
                  {/*<a href="">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Now{" "}
                    </button>
                  </a>*/}
                </div>
              </div>
            </div>
            <div className="flex flex-col min-w-0 break-words bg-white mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    Content Creation
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $125,000 Annually or $34,995.00/Quarter
                  </div>
                  <div className="border-t"></div>
                </div>
                <div className="text-left mt-5 max-w-450-px">
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">1.</b>
                    One 4-6 hour on site photo / video shoot to create for all
                    social platforms, ads, etc. $2,495 one time payment.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">2.</b>
                    Custom video creation such as VSL, click funnel, course
                    filming, requires custom quote. $10K+
                  </div>
                  {/*<a href="">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Now{" "}
                    </button>
                  </a>*/}
                </div>
              </div>
            </div>
            <div className="flex flex-col min-w-0 break-words bg-white mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    Video Editing
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $125,000 Annually or $34,995.00/Quarter
                  </div>
                  <div className="border-t"></div>
                </div>
                <div className="text-left mt-5 max-w-450-px">
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">1.</b>A
                    total of 10 video reels + coverage image that can be used
                    for all social platforms. $995/Mo
                  </div>
                  {/*<a href="">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Now{" "}
                    </button>
                  </a>*/}
                </div>
              </div>
            </div>
            <div className="flex flex-col min-w-0 break-words bg-white mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    Event Pass
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $125,000 Annually or $34,995.00/Quarter
                  </div>
                  <div className="border-t"></div>
                </div>
                <div className="text-left mt-5 max-w-450-px">
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">1.</b>
                    $995 annual payment for all access event pass to any KM
                    events
                  </div>
                  {/*<a href="">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Now{" "}
                    </button>
                  </a>*/}
                </div>
              </div>
            </div>
            <div className="flex flex-col min-w-0 break-words bg-white mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    Paid Advertisement Management
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $125,000 Annually or $34,995.00/Quarter
                  </div>
                  <div className="border-t"></div>
                </div>
                <div className="text-left mt-5 max-w-450-px">
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">1.</b>
                    Instagram & Facebook ads combo $1995/mo. Ad spend not
                    included.
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">2.</b>
                    Google/Youtube ads combo $2,495/mo. Ad spend not included
                    *Combine all 4 platforms $3,495/m*
                  </div>
                  {/*<a href="">
                    <button
                      className="bg-blueGray-800 text-white w-full active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 mt-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      id="submit_btn"
                      type="button"
                    >
                      Buy Now{" "}
                    </button>
                  </a>*/}
                </div>
              </div>
            </div>
            <div className="flex flex-col min-w-0 break-words bg-white mb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    Website Creation
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $125,000 Annually or $34,995.00/Quarter
                  </div>
                  <div className="border-t"></div>
                </div>
                <div className="text-left mt-5 max-w-450-px">
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">1.</b>
                    Custom website starting at $4,995+
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">2.</b>
                    Custom software build out, interactive website with back
                    office, tools, etc. Starts at $14,995+
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col min-w-0 break-words bg-white mb-6 pb-6 shadow-xl rounded-lg">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    Launch Your Agency
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $19,995 One Time Payment
                  </div>
                  <div className="border-t"></div>
                </div>
                <div className="text-left mt-5 max-w-450-px">
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">1.</b>
                    100k followers
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">2.</b>
                    Content quality control
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">3.</b>
                    Website creation
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">4.</b>
                    An entity and business bank acct
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">5.</b>
                    Service offerings materials
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">6.</b>
                    Payment processing account
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">7.</b>
                    Contract templates
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">8.</b>
                    Service provider catalogue
                  </div>
                  <div className="mb-4 text-blueGray-600">
                    <b className="mr-2 text-lg mt-5 text-blueGray-400">9.</b>
                    Basic CRM setup
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

/*
 <div className="flex flex-col min-w-0 break-words bg-white mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    Community All Access
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $995 One-time payment
                  </div>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    $99/month thereafter
                  </div>
                  <div className="mb-2 text-blueGray-600 mt-5 border-t">
                    <i className="fas fa-briefcase mr-2 text-lg mt-5 text-blueGray-400"></i>
                    Solution Manager - Kerns Marketing Officer
                  </div>
                  <div className="mb-2 text-blueGray-600">
                    <i className="fas fa-university mr-2 text-lg text-blueGray-400"></i>
                    University of Computer Science
                  </div>
                </div>
              </div>
            </div>*/
