import React, { useEffect } from "react";
import PropTypes from "prop-types";
import CourseLink from "views/course/CourseLink";
import dosdonts from "assets/img/dosdonts.png";

export default function CardCourse({ color }) {
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    console.log("user", user);

    if (user && user.token) {
      if (new Date(user.expiration).valueOf() < Date.now()) {
        window.location = "/auth/login";
      }
    }
  }, []);

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="relative flex flex-col bg-white rounded shadow-sm">
          <div className="relative  w-full p-4 max-w-full h-8 flex-grow flex-1 flex flex-col justify-center items-center text-3xl">
            <h2
              className={
                "font-bold text-center " +
                (color === "light" ? "text-blueGray-700" : "text-white")
              }
            >
              Social Media Marketing Tips And Tricks With Cody Kerns
            </h2>
          </div>
          <div className="flex flex-col lg:flex-row justify-evenly shadow-md">
            <div className="p-2 w-full shadow-lg aspect-w-16 aspect-h-9 overflow-hidden">
              <video controls autoPlay muted>
                <source
                  src="https://kmbizdev-dosdonts-videos.s3.amazonaws.com/VSL+for+Cody.mp4"
                  type="video/mp4"
                />
                Your Browser does not support the video element
              </video>
            </div>
            <div className="container px-4 mx-auto">
              <div className="flex flex-wrap my-4">
                <div className="w-full">
                  <span className="text-4xl block my-4 p-3 text-lightBlue-600 rounded shadow-xl font-bold">
                    Grow Your Brand!
                  </span>
                </div>
                <div className="w-full">
                  <span className="text-4xl block my-4 p-3 text-lightBlue-600 rounded shadow-lg font-bold">
                    Gain More Followers!
                  </span>
                </div>
                <div className="w-full">
                  <p className="text-4xl block my-4 p-3 text-lightBlue-600 rounded shadow-md font-bold">
                    Get More Clients!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-evenly shadow-md">
            <h1 className="text-3xl uppercase font-bold p-4 text-center lg:text-left leading-normal mt-0 mb-2 text-lightBlue-800">
              Current Courses
            </h1>
          </div>
          <CourseLink
            spanText="Instagram Do's and Dont's"
            sourceImage={dosdonts}
            paragraphText="Video series filled with the Do's and the Dont's for successful
            Instagram Marketing"
            routeID={1}
          />
        </div>
      </div>
    </>
  );
}

CardCourse.defaultProps = {
  color: "light",
};

CardCourse.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
