import React, { useEffect } from "react";

const CourseAlert = ({ showAlert, setShowAlert }) => {
  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showAlert, setShowAlert]);

  return (
    <>
      {showAlert ? (
        <div className="text-white p-4 py-4 border-0 rounded w-full bg-emerald-400 absolute top-0 left-0 z-50">
          <div className="flex flex-wrap items-center"></div>
          <span className="text-xl inline-block mr-4 align-middle">
            <i className="fas fa-bell" />
          </span>
          <span className="inline-block align-middle mr-8 text-lg">
            <b className="capitalize">Congratulations!</b> You unlocked new
            knowledge!
          </span>
          <button
            className="absolute bg-transparent text-2xl h-full w-8 lg:w-12 font-semibold leading-none right-0 top-0 outline-none focus:outline-none"
            onClick={() => setShowAlert(false)}
          >
            <i className="fas fa-times"></i>
            {/* <span className="pr-4 ">X</span> */}
          </button>
        </div>
      ) : null}
    </>
  );
};

export default CourseAlert;
