import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import VideoNavigator from "views/course/VideoNavigator";
import { useParams } from "react-router-dom";
import { getCourseTitle } from "services/kmbizdev.service";

export default function CardHeroVideo({ color }) {
  const { courseId } = useParams();
  const [courseTitle, setCourseTitle] = useState("Course Title");

  useEffect(() => {
    async function fetchCourseTitle() {
      const title = await getCourseTitle(courseId);
      setCourseTitle(title.data);
    }
    fetchCourseTitle();
  }, [courseId]);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    console.log("user", user);

    if (user && user.token) {
      if (new Date(user.expiration).valueOf() < Date.now()) {
        window.location = "/auth/login";
      }
    }
  }, []);

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="relative flex flex-col bg-white rounded shadow-sm">
          <div className="relative  w-full p-4 max-w-full h-8 flex-grow flex-1 flex flex-col justify-center items-center text-3xl">
            <h2
              className={
                "font-bold text-center " +
                (color === "light" ? "text-blueGray-700" : "text-white")
              }
            >
              {courseTitle} With Cody Kerns
            </h2>
          </div>
          <div className="flex flex-col lg:flex-row justify-evenly shadow-md"></div>
        </div>
        <VideoNavigator courseRouteId={courseId} />
      </div>
    </>
  );
}

CardHeroVideo.defaultProps = {
  color: "light",
};

CardHeroVideo.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
