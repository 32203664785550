import React, { useState } from "react";
import CourseAlert from "./CourseAlert";

const VideoPlayer = ({
  shouldAutoPlay,
  onTimeUpdate,
  currentVideo,
  foundInCompletedVideos,
}) => {
  const [showAlert, setShowAlert] = useState(false);

  return (
    <div className="w-full sm:w-1/2">
      <div className="bg-white m-2 rounded shadow-md flex flex-col">
        <div className="flex justify-center gap-2 flex-col">
          <span className="text-lg text-center center inline-block py-1 px-2 rounded text-white font-bold bg-lightBlue-600 uppercase last:mr-0 mr-1">
            {currentVideo.videoTitle}
          </span>
          <p className="text-2xl p-2 text-center lg:text-left">
            <strong>{currentVideo.videoDescription}</strong>
          </p>
          <div className="relative">
            <CourseAlert showAlert={showAlert} setShowAlert={setShowAlert} />
          </div>
        </div>
        <div
          className="relative border-black border-2"
          style={{ paddingTop: "56.25%" }}
        >
          <video
            controls
            autoPlay={shouldAutoPlay}
            className="absolute top-0 left-0 w-full h-full"
            onTimeUpdate={(e) => {
              const percentage =
                (e.target.currentTime / e.target.duration) * 100;
              if (percentage > 85 && !foundInCompletedVideos) {
                setShowAlert(true);
              }
              onTimeUpdate(percentage);
            }}
          >
            <source src={currentVideo.videoUrl} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
